import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { countryAction, SettingAction, } from "../../redux/common/action";

function Footer() {
    var Slug = window.location.pathname;
    var SplitUrl = Slug.substring(0, Slug.lastIndexOf("-") + 1); 
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const countrys: any[] = useSelector((state: any) =>
        state.country.countryList ? state.country.countryList : []
    );
    const getSetting: any = useSelector((state: any) =>
        state.setting.getSetting ? state.setting.getSetting : []
    );

    const course: any = useSelector((state: any) =>
        state.course.allCourse ? state.course.allCourse : []
    );


    const activecourse = course && course?.filter((data: any) => {
        return data?.status == 1 && data?.is_popular == 1;
    })
    useEffect(() => {
        dispatch(countryAction.getAllCountry({ status: 1 }));
        return () => { };
    }, []);
    const CountryRedirect = (e: any, val: any) => {
        e.preventDefault();
        /* history.push("country/"+val); */
    }

    return (
        <>
            <footer className="footer">

                <div className="footer-top" style={{ display: SplitUrl === '/top-universities-in-' ? 'none' : '' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 __mob">
                                <div className="footer-widget footer-menu">
                                    <h2 className="footer-title">services</h2>
                                    <ul>
                                        <li><a href={"/scholarship"}>scholarship</a></li>
                                        <li><a href={"/mentor"}>mentor</a></li>
                                        <li><a href={"/counsellor"}>counsellor</a></li>
                                        <li><a href={"/coaching"}>coaching</a></li>
                                        <li> <Link to={"/universities"} >universities</Link></li>
                                        <li><a href={"/course"}>courses</a></li>
                                        <li><a href={"/accommodation"}>accommodation</a></li>

                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 __mob">
                                <div className="footer-widget footer-menu">
                                    <h2 className="footer-title">useful links</h2>
                                    <ul>
                                        <li><a href={"/contact-us"}>contact</a></li>
                                        <li><a href={"/about-us"}>about</a></li>
                                        <li> <a href="https://www.admissify.com/blog/" >blog</a></li>
                                        {/* <li> <Link to={"/not-found"} target="_blank" rel="noopener noreferrer">blog</Link></li> */}
                                        <li> <Link to={"/faqs"} >faqs</Link></li>
                                        <li><a href={"/career"}>career</a></li>
                                        <li><a href={"/terms-conditions"}>term & conditions</a></li>
                                        <li><a href={"/privacy-policy"}>privacy policy</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 __mob">
                                <div className="footer-widget footer-menu">
                                    <h2 className="footer-title">study courses</h2>
                                    <ul>
                                        {activecourse && activecourse?.map((data: any, i: any) => {
                                            let level=data?.level == 1 ? "ug": data?.level == 2 ? "pg" : "";
                                            return (
                                                i < 8 && <li key={i}><a className="text-lowercase" href={"/course/" + data?.code + '/' + level} title="">{data?.title}</a></li>
                                            )

                                        })}
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 __mob">
                                <div className="footer-widget footer-menu ">
                                    <h2 className="footer-title">contact us</h2>
                                    <div className="footer-about-content">
                                        <p><span>head office:</span> {getSetting[0]?.address}</p>
                                        <p><span>Email: </span> <a className="__hyperlinks" href={"mailto:" + getSetting[0]?.email}>{getSetting[0]?.email}</a></p>
                                        <p><span>Phone: </span>  <a className="__hyperlinks" href={"tel:" + getSetting[0]?.phone}> {getSetting[0]?.phone}</a></p>
                                        <ul className="social social__mob">
                                            <li><a href="https://www.facebook.com/admissify/" data-toggle="tooltip"
                                                data-placement="bottom" title="facebook" target="_blank"><i
                                                    className="fa fa-facebook"></i></a></li>
                                            <li><a href="https://twitter.com/admissify?lang=en" data-toggle="tooltip" data-placement="bottom"
                                                title="twitter" target="_blank"><i className="fa fa-twitter"></i> </a></li>
                                            <li><a href="https://www.linkedin.com/company/admissifyedutechpvt.ltd/?originalSubdomain=in" data-toggle="tooltip"
                                                data-placement="bottom" title="linkedin" target="_blank"><i
                                                    className="fa fa-linkedin-square"></i> </a></li>
                                            <li><a href="https://www.instagram.com/admissify/" data-toggle="tooltip"
                                                data-placement="bottom" title="instagram" target="_blank"><i
                                                    className="fa fa-instagram"></i> </a></li>
                                        </ul>
                                        <hr />
                                        <p><span>head offices: </span></p>
                                        <div className="offie">
                                            <a href={"/contact-us#offices"}>New Delhi</a>
                                            <a href={"/contact-us#offices"}>London</a>
                                            <a href={"/contact-us#offices"}>Boston</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row mob_mt-40">
                            <div className="col-12">
                                <div className="countrybx">
                                    <h6>Countries</h6>
                                    <ul>
                                        {countrys && countrys?.map((data: any, i: any) => {

                                            return (
                                                <li key={i}><a href={"/country/" + data?.slug} title="">{data?.country_name}</a></li>
                                            )

                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="copyright">
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    <div className="copyright-text text-center">
                                        <p className="mb-0">© {new Date()?.getFullYear()}  Admissify - All rights reserved. Designed & Developed by <a className="comp_url" href="https://abym.in/" target="_blank">AbyM
                                            Technology</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
        </>
    );
}

export default Footer;
